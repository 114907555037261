export const useCustomFetch = (url: string, options?: any) => {
  return useFetch(url, {
    ...options,

    async onResponseError({ response }) {
      if (response.status === 401) {
        const { setPopup } = usePopup()
        setPopup('セッションの有効期限が切れたのでログアウトします。', 'red')

        setTimeout(() => {
          window.location.href = '/logout'
        }, 2000)
      }
    },
  })
}
