export const demoPageArtifacts = [
  {
    imageUrl: '/images/cgbank/demoPage/1_4600_cgworks03_Living_room_small_space_sophisticated_design_with_nav_6b14c883-f174-440b-96e1-2d48abe04c9f.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/2_4176_cgworks02_cool_design_luxury_restauran_has_dark_wood_with_moden_91f0d7f7-decb-43ed-969f-97748b1680e8.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/3_4640_cgworks03_Hotel_bar_lounge_luxurious_design_geometric_tiles_on__fba4924d-866a-46d5-ba7a-7ee93dd9f279.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/4_4556_cgworks03_Hotel_open_lounge_space_low_ceilings_pink-beige_colou_1f819201-b590-4a64-a7fe-7fa75ebb9921.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/5_4510cgworks03_Restaurant_design_based_on_subdued_woodgrain_white_an_99959418-48ba-46a8-9ec6-e1e9ecf2e75e.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/6_4532_anonymous_Contemporary_offices_free-address_skeleton_ceilings_m_a3dad8fd-dd13-48f2-9207-cb2490af730c .webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/7_4260_cgworks02_the_office_meeting_room_luxyry_has_dark_tone_with_mod_b4e0c0bb-304d-4c4d-b7d7-7ff0c90f815d.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/8_4175_cgworks02_cool_design_luxury_restauran_has_dark_wood_with_moden_d4c009bf-0664-4411-86a2-ebff2ee06533.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/10_4191_cgworks02_cool_design_luxury_the_office_has_darkness_dark_inter_b9a57461-41d4-4ce6-bbdc-e37bf0038a65.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/11_4002_cgworks02_cool_design_office_has_materials_for_a_warm_naturalmi_11783c8c-4670-49c0-96fd-fc9a42ff2f4b.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/12_4622_cgworks03_Restaurant_low_ceilings_cyberpunk_furniture_and_walls_d59337ea-e65e-4d9c-9518-c3285e9f4a1b.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/13_4160_cgworks02_cool_design_luxury_office_meeting_room_has_dark_wood__7e86f66c-adc6-4a36-bd43-a9eb03d7f7b5.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/14_4203_cgworks02_cool_design_luxury_the_restauran_has_darkness_dark_in_95829dd4-21a9-41ec-911b-3e9a12ad92c8.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/15_4221_cgworks02_the_office_has_modern_interior_design_earthen_tones_t_83be777b-6199-4906-a17a-43a633e40b73.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/16_4630_cgworks03_Hair_salon_terrazzo_floors_light_white_and_silver_ton_2d8f8305-ac8b-433c-90ec-fb505c7f812e.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/17_4500_cgworks03_Living_room_in_grey_and_black_minimal_design_night_wh_d3ec35dc-9453-4f57-afd7-d4a6ec25c491.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/18_4533_cgworks03_Modern_office_with_a_large_table_in_the_centre_of_whi_990830a1-4657-424a-924a-92cf00e67d98.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/19_4241_cgworks02_the_hotel_luxyry_has_dark_tone_with_modern_wood_wall__0d09fcd2-59f5-4660-9ed3-ad832fbb3261.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/20_4617_cgworks03_Clothing_store_gray-colored_floor_and_ceiling_psyched_dd8f36a2-ff39-4a76-bf52-255479df6534.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/21_4557_cgworks03_Food_plaza_contemporary_design_in_blue_yellow_and_whi_37b441c5-7382-449b-a9b2-3174a1749074.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/22_4117_cgworks02_cool_design_the_restauran_has_moden_style_cristalmood_193ffb71-2f40-4966-add7-875850ce3ce3.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/23_4187_cgworks02_cool_design_luxury_restauran_has_interiors_around_sky_11afd6e1-19ac-4388-916b-580080a094aa.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/24_4584_cgworks03_Open_office_spaces_dark_grey_and_light_wood_tones_pal_1bd868e6-d34f-4c61-b629-3538c8900d67.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/25_4594_cgworks03_Airport_lounges_luxurious_spaces_gold_accents_beige_t_710940af-8788-4cb7-bf79-e247b0350f42.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/26_4261_cgworks02_the_office_meeting_room_luxyry_has_dark_tone_with_mod_98c2406e-3961-4f20-9510-7c14cbe672ee.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/27_4127_cgworks02_cool_design_the_hotel_lobby_has_moden_style_cristalmo_5f88ebf6-112d-45eb-bb45-61d82b410b77.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/28_4648_cgworks03_Open_office_spaces_contemporary_sparkling_lighting_gr_8d80e6f9-318a-437f-b78c-a0d9fc21ee60.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/29_4663_cgworks03_Office_lounge_space_pastel_colour_scheme_grey_accent__97465701-4c00-4300-80d8-1a4b36c27dac.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/30_4625_cgworks03_Hotel_reception_lobby_geometric_patterned_walls_lime__5b366b4b-cfc3-4777-82b3-6318a6dec903.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/31_4595_cgworks03_Living_room_small_space_modern_design_pastel_colors_l_ed6e4aec-54b8-4cd1-9c26-c18490916998.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/32_4632_cgworks03_Bookshop_contemporary_design_with_light_wood_grain_to_a90d57f9-0831-48ec-9ec5-317d20eaddb3.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/33_4591_cgworks03_Childrens_room_modern_design_luxury_calm_space_archit_cda1901a-1114-4786-aa8d-94bdbd082f25.webp',
    isFavorite: 0,
  },
  {
    imageUrl: '/images/cgbank/demoPage/7_4260_cgworks02_the_office_meeting_room_luxyry_has_dark_tone_with_mod_b4e0c0bb-304d-4c4d-b7d7-7ff0c90f815d.webp',
    isFavorite: 0,
  },
]
