import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const router = useRouter()
  const { public: { sentry } } = useRuntimeConfig()
  if (sentry.environment === 'production' || sentry.environment === 'staging') {
    if (!sentry.dsn) {
      return
    }

    Sentry.init({
      app: nuxtApp.vueApp,
      dsn: sentry.dsn,
      environment: sentry.environment,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1,
      tracePropagationTargets: ['https://my-renderer.jp', 'https://staging-cgw.my-renderer.jp'],
      replaysSessionSampleRate: 1.0,
      replaysOnErrorSampleRate: 1.0,
    })
  }
})
