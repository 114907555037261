export default defineNuxtRouteMiddleware(async (to) => {
  const allowedPaths = ['/', '/privacy', '/term', '/sctl', '/cgbank', '/cgbank/sctl', '/cgbank/privacy', '/cgbank/term', '/cgbank/demo']
  if (allowedPaths.includes(to.path)) { return }

  const accountStore = useAccountStore()
  const router = useRouter()
  const requestURL = useRequestURL()
  const user = accountStore.authUser?.user

  // cgbankからのアクセス
  const isCGBank = requestURL.host.includes('cgbank') || to.path.includes('cgbank') || user.from === 'cgbank'
  // 規約関係のパス以外からのアクセス
  const isNotAdminPath = !['/verify', '/admin', '/consent'].includes(to.path)

  if (!user) {
    await accountStore.setUpUser()
    return
  }

  const redirectTo = async (path) => {
    if (path !== to.path) {
      await router.push(path)
    }
  }

  const needsVerification = user => !user.isEmailVerified
  const needsConsent = (user, isCGBank) => {
    if (isCGBank) {
      return !user.isCbTermAgreed
    } else {
      return !user.isTermAgreed
    }
  }

  const needsProfileCompletion = (user) => {
    const { firstName, lastName, companyName, phoneNumber } = user
    return [firstName, lastName, companyName, phoneNumber].some(value => !value)
  }

  const needsCaching = user => user.cgBankStatus !== 'ENABLED'

  const handleProfileRedirection = async () => {
    if (needsVerification(user)) {
      return await redirectTo(`${isCGBank ? '/cgbank' : ''}/verify`)
    }

    if (needsProfileCompletion(user) && isCGBank && needsCaching(user) && to.path === '/cgbank/pricing') {
      return redirectTo('/cgbank/demo')
    }

    if (needsConsent(user, isCGBank)) {
      return await redirectTo(`${isCGBank ? '/cgbank' : ''}/consent`)
    }

    if (needsProfileCompletion(user)) {
      return await redirectTo(`${isCGBank ? '/cgbank' : ''}/profile`)
    }

    if (isCGBank && needsCaching(user)) {
      return await redirectTo('/cgbank/pricing')
    }

    if (to.path === '/consent' && user.isTermAgreed) {
      return await redirectTo(isCGBank ? '/cgbank/artifacts' : '/generate')
    }

    if (to.path === '/verify' && user.isEmailVerified) {
      return await redirectTo(isCGBank ? '/cgbank/artifacts' : '/generate')
    }

    return false
  }

  if (
    !allowedPaths.includes(to.path)
    && isNotAdminPath
  ) {
    const redirectHappened = await handleProfileRedirection()
    if (redirectHappened) { return }

    if (isCGBank && !needsCaching && user.cgBankStatus === 'DISABLED' && user.isEmailVerified && user.isCbTermAgreed) {
      const response = await $fetch('/api/cgbank/stripe/subscriptions', {
        method: 'POST',
        body: { test: 1 },
      })
      const url = response?.message
      if (url) {
        window.location.href = url
        return
      }
    }
  }

  await accountStore.setUpUser()
})
