export default defineNuxtPlugin(async () => {
  const route = useRoute()

  if (route.path === '/'
    || route.path === '/privacy'
    || route.path === '/sctl'
    || route.path === '/term'
    || route.path === '/cgbank'
    || route.path === '/cgbank/sctl'
    || route.path === '/cgbank/privacy'
    || route.path === '/cgbank/term'
    || route.path === '/cgbank/demo'
  ) {
    return
  }

  const { me } = useAccountStore()
  await me()
})
