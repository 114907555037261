import validate from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.14.9_@unocss+re_jmltx7yansak2xexfe4pyuk2vq/node_modules/nuxt/dist/pages/runtime/validate.js";
import setup_45global from "/app/middleware/setup.global.ts";
import manifest_45route_45rule from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.14.9_@unocss+re_jmltx7yansak2xexfe4pyuk2vq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  setup_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  admin: () => import("/app/middleware/admin.ts")
}