<template>
  <div class="price-component">
    <header class="price-header">
      利用料金
    </header>
    <section class="price-details">
      <p>月額</p>
      <h3 style="display: flex; justify-content: center; align-items: baseline;font-size: 4rem;">
        <span class="price-span left">￥</span>
        <div>
          11,000
        </div>
        <span class="price-span right">(税込)</span>
      </h3>
    </section>
    <div class="blocks-container">
      <div class="block">
        <icon-none-price class="icon-none-price" />
        <div class="image-container">
          <img
            src="/images/my-renderer/my-renderer-logo.webp"
            alt="MyRenderer Logo"
            class="my-renderer-logo"
          >
          <p>ご利用の方は<span>無料で使える</span></p>
        </div>
      </div>
      <div class="block">
        <v-icon class="heart-icon">
          mdi-heart
        </v-icon>
        <p>
          お気に入り画像を<span class="block-span"><br>保存できる</span>
        </p>
      </div>
      <div class="block">
        <v-icon class="icon-download">
          mdi-tray-arrow-down
        </v-icon>
        <p>
          ダウンロード回数<span class="block-span"><br>無制限</span>
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.price-component {
  background-color: white;
  height: 100%;
  border-radius: 8px;
  max-width: 1024px;
  margin: 0 auto 100px auto;
  gap: 2rem;

  > .price-header {
    font-size: 1.5em;
    color: #ddd;
    background-color: black;
    padding: 10px 0;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 3px;
  }

  > .price-details {
    > p {
      font-size: 1.25rem;
      margin: 0;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: baseline;
    }

    > h3 {
      font-size: 2em;
      text-align: center;
      > .price-span {
        font-size: medium;
      }

      > .left {
        font-size: 2rem;
      }

      > .right {
        color: var(--Color-Light-Text, #737584);
      }
    }
  }

  > .blocks-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 50px;
    gap: 2rem;

    > .block {
      border-radius: 0.125rem;
      background: #f6f6f7;
      display: flex;
      width: 100%;
      padding: 2.5rem 0;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      > .image-container {
        text-align: center;

        > .my-renderer-logo {
          width: 7.75rem;
          height: auto;
          max-width: 100%;
          object-fit: contain;
        }
      }

      > .icon-none-price {
        width: 2.86013rem;
        height: 3.125rem;
      }

      > .heart-icon {
        font-size: 3.3125rem;
      }

      > .icon-download {
        font-size: 3.52944rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .price-component {
    margin: 0 1.3rem;
    gap: 2rem;
  }

  .blocks-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .block {
    width: 100%;
    margin-bottom: 20px;
  }
}

p {
  color: var(--Color-Text, #18181b);
  text-align: center;
  font-family: YuGothic;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0;
}
</style>
