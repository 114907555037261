import { createVuetify } from 'vuetify'

export default defineNuxtPlugin((app) => {
  const vuetify = createVuetify({
    // ... your configuration
    ssr: true,
    theme: {
      defaultTheme: 'light',
      themes: {
        light: {
          dark: false,
          colors: {
            'primary': '#212836',
            'secondary': '#B24431',
            'background': '#19222C',
            'error': '#DA331C',
            'primary-light': '#33688A',
            'surface': '#FFFFFF',
            'on-surface': '#FFFFFF',
          },
        },
        dark: {
          dark: true,
          colors: {
            'primary': '#2B2B2B',
            'secondary': '#B24431',
            'background': '#222222',
            'error': '#DA331C',
            'primary-light': '#7D7D7D',
            'surface': '#FFFFFF',
            'on-surface': '#FFFFFF',
          },
        },
      },
    },
    defaults: {
      VBtn: {
        variant: 'flat',
      },
      VTextField: {
        density: 'compact',
        hideDetails: 'auto',
        variant: 'outlined',
        singleLine: true,
      },
      VAutocomplete: {
        density: 'compact',
        variant: 'outlined',
        bgColor: 'primary',
        hideDetails: 'auto',
        singleLine: true,
      },
      VFileInput: {
        density: 'compact',
        variant: 'outlined',
        bgColor: 'primary',
        hideDetails: 'auto',
        singleLine: true,
      },
      VSelect: {
        density: 'compact',
        variant: 'outlined',
        hideDetails: 'auto',
        singleLine: true,
      },
      VList: {
        bgColor: '#002842',

      },
    },
  })
  app.vueApp.use(vuetify)
})
