import type { Directive } from 'vue'

const onClickOutside: Directive = {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event: Event) => {
      if (!(el === event.target || el.contains(event.target as Node))) {
        binding.value(event, el)
      }
    };

    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted: (el) => {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  },
}

export default defineNuxtPlugin(nuxtApp => {
  nuxtApp.vueApp.directive('on-click-outside', onClickOutside)
})