import type {
  IUserAccount,
} from '~/interfaces'

export const useAccountStore = defineStore(
  'account',
  () => {
    const user = ref<IUserAccount | null>()
    const authUser = ref()

    // User
    const setUpUser = async () => {
      const { data } = await useCustomFetch(`/api/users`)
      user.value = data.value as IUserAccount
    }

    const updateUserName = async (firstName: string, lastName: string) => {
      const { data } = await useCustomFetch('/api/users/', {
        method: 'PATCH',
        body: {
          firstName,
          lastName,
        },
      })

      if (data.value) {
        await setUpUser()
      }
    }

    const setUser = async (data: any) => {
      authUser.value = data
    }

    // MEMO: ここのmeはuserと同じ内容では？
    const me = async () => {
      try {
        const data = await $fetch('/api/users/me', {
          headers: useRequestHeaders(['cookie']) as HeadersInit,
        })
        await setUser(data)
      } catch (error) {
        console.log('error', error)
      }
      return authUser
    }

    const reset = () => {
      user.value = null
      authUser.value = null
    }

    return {
      me,
      authUser,
      user,
      setUpUser,
      updateUserName,
      reset,
    }
  },
  { persist: true },
)
