interface UsePopupReturn {
  popupState: globalThis.Ref<PopupState>
  setPopup: (
    msg: string,
    color: 'red' | 'green' | 'primary' | 'primary-light' | 'secondary'
  ) => void
  clearPopup: () => void
}

interface PopupState {
  message: string
  isActive: boolean
  color: string
}

export default function usePopup(): UsePopupReturn {
  const popupState = useState<PopupState>('popup', () => {
    return {
      message: '',
      isActive: false,
      color: '',
    }
  })

  const setPopup = (
    msg: string,
    color: 'red' | 'green' | 'primary' | 'primary-light' | 'secondary',
  ) => {
    popupState.value.message = msg
    popupState.value.color = color
    popupState.value.isActive = true
  }

  const clearPopup = () => {
    popupState.value.isActive = false
    popupState.value.message = ''
  }

  return { popupState, setPopup, clearPopup }
}
