import revive_payload_client_SwkAzB3VOJ from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.14.9_@unocss+re_jmltx7yansak2xexfe4pyuk2vq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_InrURn1m8d from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.14.9_@unocss+re_jmltx7yansak2xexfe4pyuk2vq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_FFFtfyH8Cx from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.14.9_@unocss+re_jmltx7yansak2xexfe4pyuk2vq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_nCQ8CDUuG8 from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.14.9_@unocss+re_jmltx7yansak2xexfe4pyuk2vq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_xju1tyD3nT from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.14.9_@unocss+re_jmltx7yansak2xexfe4pyuk2vq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_ztvHJq7vFt from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.13.0_typescript@5.5.3_vue@3.4.21_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_VF3JjD1vJc from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.14.9_@unocss+re_jmltx7yansak2xexfe4pyuk2vq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_wZP5WUpmOl from "/app/node_modules/.pnpm/@zadigetvoltaire+nuxt-gtm@0.0.13_nuxt@3.11.1_@parcel+watcher@2.4.1_@planetscale+database@1.16_7q6kkpwmrwpji5h3fcwumgnl4a/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_IrtOMZxRrZ from "/app/node_modules/.pnpm/nuxt-papa-parse@1.0.8_rollup@4.13.0/node_modules/nuxt-papa-parse/dist/runtime/plugin.mjs";
import chunk_reload_client_VbAFi4BwfY from "/app/node_modules/.pnpm/nuxt@3.11.1_@parcel+watcher@2.4.1_@planetscale+database@1.16.0_@types+node@20.14.9_@unocss+re_jmltx7yansak2xexfe4pyuk2vq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_srEZPrbWvA from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.0_@pinia+nuxt@0.5.1_rollup@4.13.0_typescript@5.5.3_vue@_2cx5lfpxxszaom5w67uw523fpe/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import apexchart_gUgipXIQGn from "/app/plugins/apexchart.ts";
import auth_vT9JWWT9pN from "/app/plugins/auth.ts";
import clickOutsideDirective_JuSoe6vGeC from "/app/plugins/clickOutsideDirective.ts";
import me_YJ4xT53NdQ from "/app/plugins/me.ts";
import sentry_3AyO8nEfhE from "/app/plugins/sentry.ts";
import vuetify_7h9QAQEssH from "/app/plugins/vuetify.ts";
export default [
  revive_payload_client_SwkAzB3VOJ,
  unhead_InrURn1m8d,
  router_FFFtfyH8Cx,
  payload_client_nCQ8CDUuG8,
  check_outdated_build_client_xju1tyD3nT,
  plugin_vue3_ztvHJq7vFt,
  components_plugin_KR1HBZs4kY,
  prefetch_client_VF3JjD1vJc,
  plugin_wZP5WUpmOl,
  plugin_IrtOMZxRrZ,
  chunk_reload_client_VbAFi4BwfY,
  plugin_srEZPrbWvA,
  apexchart_gUgipXIQGn,
  auth_vT9JWWT9pN,
  clickOutsideDirective_JuSoe6vGeC,
  me_YJ4xT53NdQ,
  sentry_3AyO8nEfhE,
  vuetify_7h9QAQEssH
]